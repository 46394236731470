header.header
    position relative
strong 
    font-weight: bold
a 
    color: #fff;
    text-decoration: none
.wrapper
    margin-right: auto
    margin-left: auto
    padding-left: 15px
    padding-right: 15px
.wrapper
    width: 1160px;
    margin: 0 auto
.navbar-item-all
    display: flex
.navbar-item
    margin 0
a.navbar-item.telefon 
    margin-left: auto;
.cover-top-info
    margin-top: 75px
.navbar
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin 0
    padding 40px 0 0 0
.telefon
    font-family: 'Roboto', sans-serif
    font-weight: 500;
    font-size: 16px;
    line-height: 22px
    color: #FFA800;
    position: relative
.telefon i.fa.fa-phone
    margin-right: 8px
.telefon:before 
    content: ''
    position: absolute
    bottom: 0
    right: 0
    width: 130px
    height: 0
    border-bottom: 1px dotted #f1a812
.colonka-all
    display flex
    flex-wrap wrap
    margin-bottom: 122px
p.cover-item
    text-shadow: 10px 15px 10px rgba(0, 0, 0, 0.15)
    color: #FFA800
    font-size: 28px
    line-height: 32px
.colonka.cover-all-item
    margin-left: 11%
.cover-top-info p
    border-left: 2px solid #FFA800 
    border-right: 2px solid #FFA800
    padding-left: 30px
.colonka.cover-all-item button
    background: #FFA800;
    border: 1px solid #FFA800;
    color #fff
    box-sizing: border-box;
    border-radius: 6px;
    width 211px
    height 69px
    display flex
    margin-top: 32px
.text-cover-all
    position relative
.cover-item span
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-transform: uppercase;
    text-align: left;
    margin: 13px 5px 12px 13px
.text-cover-rihgt p
    text-align: right;
    position: absolute;
    right: 0;
    top: 0;
    width: 242px
.cover-all-item svg 
    width: 50px
    height: 34px
    color: #fff
    font-family: Roboto
    font-style: normal
    font-weight: bold
    font-size: 18px
    line-height: 22px
    margin: 16px 15px 12px 0
.text-block-30years 
    position relative
    font-family: 'Roboto', sans-serif
    font-weight: 100
    font-size: 38px
    line-height: 105%
    letter-spacing: 0.06em
    text-transform: uppercase
    color: #F7F7F7
    text-shadow: 10px 15px 10px rgba(0, 0, 0, 0.15)
    margin-left: 54px
    display: flex
    flex-direction: column
.text-block-30years strong
    font-weight: 900
    width: 145px;
    padding-top: 107px;
.text-block-30years:before
    content ''
    position absolute
    bottom -1px
    left -20px
    border: 15px solid rgba(255, 255, 255, 0.3)
    box-sizing: border-box
    width: 249px;
    height: 394px;
.navbar ul
    display: flex;
    margin-left: auto
.navbar ul li a
    font-family: 'Roboto', sans-serif
    padding: 0;
    margin: 0 10px;
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
    text-align: right;
    letter-spacing: 0.03em;
    text-transform: uppercase
.text-block-top
    margin-top 50px
.text-block-top h1
    font-family: 'Roboto', sans-serif
    font-weight: 900;
    font-size: 56px;
    line-height: 105%;
    letter-spacing: 0.06em;
    text-transform: uppercase;    
    color: #FFFFFF;
    text-shadow: 10px 15px 10px rgba(0, 0, 0, 0.15)
#cover
    min-height: 694px
    height: 91vh;
    position: relative;
    overflow: hidden;
    cursor: default;
.cover-image
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    min-height: 770px
    height: 100vh;
    overflow: hidden;
    pointer-events: none;
    z-index: -100
.masc-cover
    content: ''
    position: absolute;
    top: 0;
    left: 0;
    background: radial-gradient(100% 661.22% at 0% 0%, rgba(15, 24, 47, 0.82) 0%, rgba(0, 11, 38, 0.82) 100%);
    z-index: 5;
    width: 100%
    min-height: 770px
    height: 100vh
    z-index -5
.masc-cover .y0
    display flex
.masc-cover .y-item 
    display: flex
    align-items: center
    width: 480px
    min-height: 770px
    height: 100vh
    border-left: 1px solid #fff
    opacity: 0.15
    z-index: 5
.colonka-all
    display flex
    flex-wrap wrap
    margin-bottom: 122px
#contact .wrapper
    display flex
    justify-content center
