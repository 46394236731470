@media only screen and (max-width: 1440px){
#cover{
    min-height: 694px;
}

    }
@media only screen and (max-width: 1280px) {
    .wrapper {
        margin 0 auto
        width 960px
    }

    #service .row {
        flex-wrap: wrap;
    }

    #service:before {
        background none
    }

    .service-block {
        width: 50%;
    }

    .service-block p {
        width: 100%;
    }

    .service-block img {
        width 100%
    }

    #promo p {
        width: 100%;
    }

    #promo h2 {
        width 100%
    }

    .promo-block:last-child {
        padding: 25px 0;
    }

    #triggers .row {
        flex-wrap: wrap
    }

    .trig-block {
        margin 20px 10px
    }
    .masc-cover .y0 {
    display none
    }
}

@media only screen and (max-width: 991px) {
    .service-block p {
    font-size: 16px;
    }
    .wrapper {
        margin: 0 auto;
        width: 730px;
    }
    .navbar-item-all {
        flex-direction: column;
        justify-content: center;
        text-align: center;
    }

.navbar-item {
    margin: 15px 0;
}
    .navbar ul {
        margin: auto;
    }

    a.navbar-item.telefon {
        margin: auto;
    }
    .text-cover-rihgt {
        display none
        }
.promo-block-right {
    width 100%
    }
#contact .y0 {
    display none
    }
.colonka.text-block-30years {
    margin: 0;
}
.colonka.cover-all-item {
    margin-left: 0;
}
h2 {
    line-height: 44px
}
.colonka-all{
    margin 0
    }
.colonka.cover-all-item {
    margin-left: 17%;
}
}

@media only screen and (max-width: 767px) {
.wrapper {
    width: 449px;
}
.text-block-top h1 {
        font-size: 30px;
    }
.service-block {
    width: 100%;
}
.promo-block-left {
    display: none;
}
.promo-block:last-child:after{
    left 0
    }

footer {
    height auto
}
footer .wrapper {
    width: 100%;
}
.footer-block p {
    display none
    }
.pris-block {
    margin: 0;
    }
section#contact {
    height: 100%;
}
.promo-block-row {
    padding: 0;
}
#promo h2 {
    font-size: 30px;
    }
.tel {
    font-size: 30px;
    margin 20px 0
}
.mail {
    font-size: 25px;
}
#contact h2 {
    margin: 30px 0;
    }
.prise-item {
    font-size: 58px;
    }
.colonka.cover-all-item {
    margin-left: 0;
}
.navbar ul {
    margin: 15px 0;
}
#cover {
    min-height: auto;
    height auto
}
.text-block-30years strong {
    padding: 20px 0;
}
.text-block-30years {
    font-size: 30px;
}
.text-block-30years:before {
        bottom: -159px;
    }
}

@media only screen and (max-width: 479px) {
.wrapper {
    width: 100%;
}
.navbar ul {
    flex-direction: column;
}
section#contact {
    height: auto;
}
.navbar {
    padding 0
    }
    }