html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, font, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td 
    margin: 0
    padding: 0
    border: 0
    outline: 0
    font-size: 100%
    vertical-align: baseline
    background: transparent

html
    font-family: 'Roboto', sans-serif
    font-style: normal
    font-weight: normal
    font-size: 16px
    line-height: 24px
    color: #fff
    background: #F7F7F7
body
    font-family: 'Roboto', sans-serif
    font-style: normal
    font-weight: normal
    font-size: 16px
    line-height: 24px
h1 
    font-weight: 900
    font-size: 56px
h2 
    font-family: 'Roboto', sans-serif
    font-style: normal
    font-weight: 300
    font-size: 42px
    line-height: 32px
a 
    color: #fff
    text-decoration: none

p 
    font-family: 'Roboto', sans-serif
    font-style: normal
    font-weight: normal
    font-size: 16px
    line-height: 22px
#service
    margin 60px 0
#service:before
    content ''
    top 137px
    left: 0
    position: absolute
    width: 100%
    height 15px
    background: #FFA800
.service-block 
    position: relative
    min-height: 1px
    padding-left: 15px
    padding-right: 15px

#service .row 
    justify-content: center
    display: flex
.service-block p 
    width: 370px
    height: 152px
    background: #FF9900
    padding: 20px 30px 24px 30px
    font-size: 21px
    line-height: 27px
// promo
#promo
    font-family: 'Roboto', sans-serif
    font-style: normal
    font-weight: normal
    font-size: 16px
    line-height: 22px
    color: #333
    overflow: hidden;
#promo h2
    font-weight: 300
    font-size: 42px
    line-height: 46px
    width 808px
    padding-left 29px
    border-left 4px solid #FFA800 
    margin 0 0 29px 0
#promo .row
    display flex
    justify-content flex-start
    width 100%
#promo p 
    font-size: 16px
    line-height: 22px
    width: 866px  
.promo-block img 
    width 100%
    height 100%
.promo-block
    flex 0 1 auto
.promo-block-left
    width: 20%
.promo-block-right 
    width: 80%   
.promo-block:last-child
    padding: 50px 0px 50px 0px;
.promo-block-bg
    width 100%
    height 100%
.promo-block-left:before
    content: ''
    position absolute
    top 0
    left -7%
    background-image url(../img/content/full-promo.png)
    width 100%
    height 100%
    z-index -2
    background-repeat: no-repeat
.promo-block:last-child:after
    content: ''
    position absolute
    top 0
    background rgba(225, 228, 235, 0.9)
    width 100%
    height 100%
    z-index -1
.promo-block-row
    padding 0 30px
.promo-block:last-child:before
    content: ''
    position absolute
    top 0
    background-image url(../img/content/promo-bg.png)
    width 100%
    height 100%
    z-index -2
    background-repeat: no-repeat
#triggers .row
    display flex
    justify-content center
section#triggers:before
    content ''
    position absolute
    width: 213px
    height: 213px
    right 58px
    top -15px
    border 15px solid #E5E8EF
    box-sizing border-box
    z-index -1
section#triggers:after
    content ''
    position absolute
    width: 146px
    height: 146px
    left 102px
    bottom:  -15px
    border 15px solid #E5E8EF
    box-sizing border-box
    z-index -1
.trig-block
    width 250px
    height 250px
    background rgba(246, 246, 246, 0.6)
    border 1px solid #ffa800
    box-sizing border-box
    border-radius 3px
    margin 70px 20px
    display flex
    justify-content center
    align-items center
    flex-direction column
.trig-block img 
    width 70px
    height 70px
.trig-block h3
    font-size: 24px
    line-height: 120%
    text-align: center
    color: #000B26
    padding: 36px 10px 25px 9px;
.trig-block.img-trig-new
    background: #FFA800
.trig-block p 
    font-size: 16px
    line-height: 22px
    text-align: center
    color: #333333
    padding: 0 4px;
section#contact
    position relative
    width: 100%
    height: 610px   
    text-align: center    
.contact-image
    z-index: -1
    position: absolute
    top: 0px
    width: 100%
    height: 100%
.contact-image:before
    content: ''
    position absolute
    width 100%
    height 100%
    top 0
    left 0
    background: radial-gradient(100% 661.22% at 0% 0%, rgba(15, 24, 47, 0.82) 0%, rgba(0, 11, 38, 0.82) 100%)
#contact h2 
    text-align: center
    text-transform: uppercase
    margin 80px 0 50px 0 
#contact .y0
    display flex
#contact .y-item 
    display: flex
    align-items: center
    width: 480px
    min-height: 700px
    height: 50vh
    border-left: 1px solid #fff
    opacity: 0.15
    z-index: 5
.prise-item
    font-weight: bold
    font-size: 68px
    line-height: 32px
    text-transform: uppercase
    color: #FFA800
    padding 8px 0 0 0
.prise-currency
    font-family: 'Roboto', sans-serif
    font-size: 24px
    line-height: 24px
    color: #FFA800
    width: 142px
    margin-left 11px
    text-align: left 
.pris-block
    display: flex 
    justify-content center 
    margin 50px 0 100px 0  
.tel     
    font-weight: 900
    font-size: 56px
    line-height: 105%
    text-align: center
    letter-spacing: 0.06em
    text-transform: uppercase
    color: #FFFFFF
    text-shadow: 10px 15px 10px rgba(0, 0, 0, 0.15)

.mail
    font-size: 34px
    line-height: 34px
    margin 6px 0 80px 0
#contact button
    position relative
    width: 295px
    height: 69px
    background: #FFA800
    border-radius: 6px
    border: none
    font-weight: bold
    font-size: 18px
    line-height: 22px
    color #fff
    margin 0 auto
    display flex
    text-align: left
#contact button span 
    margin: 13px 15px 12px 14px
    text-transform: uppercase
#contact button svg 
    width: 72px
    height: 44px
    color #fff
    font-family: 'Roboto', sans-serif
    font-style: normal
    font-weight: bold
    font-size: 18px
    line-height: 22px
    margin: 12px 13px 10px 0
section#contact button:before
    content: ''
    position: absolute
    width: 229px
    height: 256px
    left: 10%
    bottom: 74%
    border: 15px solid rgba(255, 255, 255, 0.3)    
    -webkit-box-sizing: border-box
    box-sizing: border-box
    z-index -1
footer
    display flex
    height: 109px
    background: radial-gradient(100% 661.22% at 0% 0%, #0F182F 0%, #000B26 100%)
footer .row
    margin 40px 0 30px 0
    display flex
    justify-content flex-start
span.icon-all 
    margin: 0 2px
.footer-block.first
    width 173px
.footer-block.icon
    text-align: right
.footer-block.copyright
    margin 2px 0 
.footer-block p
    color: #CCCCCC
    font-family: Open Sans
    font-style: normal
    font-weight: normal
    font-size: 16px
    line-height: 150%
    margin-left 26px
.footer-block
    width 100%
.fa-vk
    padding: 4px 3px
    background: #48729e
    width: 24px
    height: 24px
.fa-facebook
    background:#3B5998
    padding: 4px 7px
.fa-twitter
    background: #00ACED
    padding 4px 4px 4px 5px
.fa-instagram
    background: #A30979
    padding 4px 5px
.fa-skype
    background: #00AFF0
    padding 4px 5px
.fa-odnoklassniki
    background: #EB722E
    padding 4px 6px