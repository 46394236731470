.sl-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  opacity: 0;
  background: rgba(52, 63, 69, 0.95);
  pointer-events: none;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}

.sl-overlay:target {
  opacity: 1;
  pointer-events: auto;
}

.sl-overlay header {
  font-weight: bold;
}


/* Кнопки */

.smartLidBtn {
  position: fixed;
  bottom: 20px;
  left: 20px;
  width: 50px;
  z-index: 5;
}

.toggle {
  position: relative;
  width: 50px;
  height: 50px;
  z-index: 1;
  display: block;
  border-radius: 25px;
  cursor: pointer;
  color: #ECEFF1;
  background: #ffa800;
  box-shadow: 0 2px 10px rgba(1, 37, 66, 0.7);
  -webkit-animation: pulse 1.5s infinite;
}

.toggle:hover {
  box-shadow: 0 2px 10px rgba(1, 37, 66, 0.5);
}

.toggle:after {
  position: relative;
  display: block;
  width: 50px;
  height: 50px;
  font-family: 'FontAwesome';
  content: "\f0c9";
  line-height: 50px;
  font-size: 20px;
  text-align: center;
}
.toggle-img {
  position: relative;
  width: 50px;
  height: 50px;
  z-index: 1;
  display: block;
  border-radius: 25px;
  cursor: pointer;
  color: #ECEFF1;
  background: #01579B;
  box-shadow: 0 2px 10px rgba(1, 37, 66, 0.7);
  -webkit-animation: pulse 1.5s infinite;
}
.toggle-img:hover {
  box-shadow: 0 2px 10px rgba(1, 37, 66, 0.5);
}
.toggle-img:after {
  position: relative;
    display: block;
    width: 50px;
    height: 50px;
    background: url(../img/line-menu.svg) center center no-repeat;
    background-size: 16px;
    content: ""
}

.toggle-input {
  display: none;
}

.toggle-input:checked + .toggle:after,
.toggle-input:checked + .toggle:before {
  background-color: #ECEFF1;
  content: "";
  height: 3px;
  width: 30px;
  position: absolute;
  left: 10px;
  top: 23px;
}

.toggle-input:checked + .toggle:after {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-animation: bar1 0.3s forwards;
  animation: bar1 0.3s forwards;
}

.toggle-input:checked + .toggle:before {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-animation: bar2 0.3s forwards;
  animation: bar2 0.3s forwards;
}

.btn-list li {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 50px;
  height: 50px;
  margin: 0;
  padding: 0;
  list-style-type: none;
  opacity: 0;
  -webkit-transition: all 0.25s cubic-bezier(0.43, 1.17, 0.69, 1.24);
  transition: all 0.25s cubic-bezier(0.43, 1.17, 0.69, 1.24);
}

.btn-list a {
  position: relative;
  display: block;
  width: 50px;
  height: 50px;
  overflow: hidden;
  line-height: 50px;
  color: #ECEFF1;
  text-decoration: none;
  text-align: center;
  border-radius: 50%;
  background: #ffa800;
  box-shadow: 0 2px 10px rgba(1, 37, 66, 0.7);
  font-size: 18px;
}

.call-form--link {
  line-height: 47px!important;
}
.call-form--link img{
  vertical-align: middle;
}
.fa-envelope-o {
  margin-left: 1px;
}

input:checked ~ .btn-list li {
  opacity: 1;
}

input:checked ~ .btn-list li:nth-child(1) {
  left: 60px;
}

input:checked ~ .btn-list li:nth-child(2) {
  left: 120px;
}

input:checked ~ .btn-list li:nth-child(3) {
  left: 180px;
}

.rings {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: auto;
  width: auto;
  border-radius: 50%;
  border: 1px solid #ffa800;
  animation-duration: 2.0s;
  animation-name: fadeAndScale;
  animation-iteration-count: infinite;
  z-index: -1;
}

.rings:nth-child(2) {
  animation-name: fadeAndScaleStep2;
}

.rings:nth-child(3) {
  animation-name: fadeAndScaleStep3;
}

@keyframes fadeAndScale {
  from {
    opacity: 1;
    transform: scale(0.8, 0.8);
  }
  to {
    opacity: 0;
    transform: scale(1.2, 1.2);
  }
}

@keyframes fadeAndScaleStep2 {
  from {
    opacity: 0.9;
    transform: scale(0.9, 0.9);
  }
  to {
    opacity: 0;
    transform: scale(1.4, 1.4);
  }
}

@keyframes fadeAndScaleStep3 {
  from {
    opacity: 0.8;
    transform: scale(1, 1);
  }
  to {
    opacity: 0;
    transform: scale(1.6, 1.6);
  }
}

@-webkit-keyframes bar1 {
  0% {
    content: "\f1e0";
    width: 46px;
    height: 50px;
    background-color: transparent;
    -webkit-transform: rotate(0deg) scale(1);
    transform: rotate(0deg) scale(1);
    top: 0;
    left: 0;
    opacity: 1;
  }
  50% {
    background-color: transparent;
    content: "\f1e0";
    width: 46px;
    height: 50px;
    top: 0;
    left: 0;
    -webkit-transform: rotate(0deg) scale(0.2);
    transform: rotate(0deg) scale(0.2);
    opacity: 0;
  }
  50.001% {
    background-color: #fff;
    left: 10px;
    top: 22px;
    content: "";
    height: 3px;
    width: 30px;
  }
  60% {
    -webkit-transform: rotate(0deg) scale(1);
    transform: rotate(0deg) scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(45deg) scale(1);
    transform: rotate(45deg) scale(1);
  }
}

@keyframes bar1 {
  0% {
    content: "\f1e0";
    width: 46px;
    height: 50px;
    background-color: transparent;
    -webkit-transform: rotate(0deg) scale(1);
    transform: rotate(0deg) scale(1);
    top: 0;
    left: 0;
    opacity: 1;
  }
  50% {
    background-color: transparent;
    content: "\f1e0";
    width: 46px;
    height: 50px;
    top: 0;
    left: 0;
    -webkit-transform: rotate(0deg) scale(0.2);
    transform: rotate(0deg) scale(0.2);
    opacity: 0;
  }
  50.001% {
    background-color: #fff;
    left: 10px;
    top: 22px;
    content: "";
    height: 3px;
    width: 30px;
  }
  60% {
    -webkit-transform: rotate(0deg) scale(1);
    transform: rotate(0deg) scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(45deg) scale(1);
    transform: rotate(45deg) scale(1);
  }
}

@-webkit-keyframes bar2 {
  0% {
    background-color: transparent;
    -webkit-transform: rotate(0deg) scale(0.2);
    transform: rotate(0deg) scale(0.2);
    opacity: 0;
  }
  50% {
    background-color: transparent;
    -webkit-transform: rotate(0deg) scale(0.2);
    transform: rotate(0deg) scale(0.2);
    opacity: 0;
  }
  60% {
    -webkit-transform: rotate(0deg) scale(1);
    transform: rotate(0deg) scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(-45deg) scale(1);
    transform: rotate(-45deg) scale(1);
  }
}

@keyframes bar2 {
  0% {
    background-color: transparent;
    -webkit-transform: rotate(0deg) scale(0.2);
    transform: rotate(0deg) scale(0.2);
    opacity: 0;
  }
  50% {
    background-color: transparent;
    -webkit-transform: rotate(0deg) scale(0.2);
    transform: rotate(0deg) scale(0.2);
    opacity: 0;
  }
  60% {
    -webkit-transform: rotate(0deg) scale(1);
    transform: rotate(0deg) scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(-45deg) scale(1);
    transform: rotate(-45deg) scale(1);
  }
}


/* Форма */

.sl-form--wrapper {
  position: relative;
  max-width: 424px;
  padding: 30px 60px;
  box-sizing: border-box;
  margin: 60px auto;
  font-family: 'Roboto', sans-serif;
  line-height: 1.3;
}

.sl-overlay--close {
  position: absolute;
  font-size: 24px;
  right: 10px;
  top: 0;
  text-decoration: none;
}

.form-title--icon {
  width: 100px;
  height: 100px;
  margin: 0 auto;
  margin-bottom: 15px;
  border-radius: 50%;
}
.sl-form {
  margin-bottom: 15px;
}

.sl-form--input {
  display: block;
  border: none!important;;
  width: 100%;
  max-width: 304px;
  box-sizing: border-box;
  margin: 15px auto;
  padding: 15px 15px 13px!important;
  outline: none;
  font: 16px 'Roboto', sans-serif;
}

.form-title--text,
.msgs {
  font-size: 18px;
  text-align: center;
}

.sl-form--button {
  display: block;
  width: 100%;
  max-width: 304px;
  border: none;
  outline: none;
  font-size: 16px;
  height: 48px;
  box-sizing: border-box;
  color: #fff;
  padding-top: 4px;
  text-transform: uppercase;
  cursor: pointer;
  margin: 0 auto;
}
.copyright-wprapper {
  text-align: center;
  padding-bottom: 0!important;
  margin-bottom: 0!important;
}

/*  Оформление  dark-space */
.dark-space {
  background-color: #263238;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
}

.dark-space .sl-overlay--close {
  color: #cfd8dc;
}

.dark-space .form-title--icon {
  box-shadow: 0 3px 15px rgba(14, 27, 33, 0.15);
}
.dark-space .form-title--title {
  color: #cfd8dc;
}

.dark-space .form-title--text {
  color: #cfd8dc;
}

.dark-space .msgs {
  color: #cfd8dc;
}

.dark-space .sl-form--input {
  background: #343f45;
  color: #cfd8dc;
  border-bottom: 2px solid #343f45;
}

.dark-space .sl-form--input:focus {
  border-bottom: 2px solid #546e7a;
}

.dark-space .sl-form--button {
  background: #0095d9;
}

.dark-space .sl-form--button:hover {
  background: rgba(0, 149, 217, 0.59);
}

.dark-space .copyright-wprapper a{
  color: #4b5358;
  text-align: center;
}
/*  Оформление  orange-style */
.maya-the-bee {
  background-color: #ffc400;
  box-shadow: 0 3px 10px rgba(75, 23, 0, 0.2);
  border-radius: 15px;
}
.maya-the-bee .sl-overlay--close {
  color: #000;
}

.maya-the-bee .form-title--icon {
  box-shadow: 0 3px 15px rgba(158, 91, 0, 0.5);
}
.maya-the-bee .form-title--title {
  color: #000;
}
.maya-the-bee .msgs {
  color: #000;
}
.maya-the-bee .sl-form--button {
  background: #000;
  border-radius: 23.5px;
}

.maya-the-bee .sl-form--input {
  background: #fff;
  color: #000;
  border-bottom: 2px solid #343f45;
  border-radius: 23.5px;
  padding: 15px 15px 15px!important;
}

.maya-the-bee .copyright-wprapper a{
  color: #c5af00;;
}
.maya-the-bee .copyright-wprapper a:hover{
  color: #ffffff;
}
.maya-the-bee-overlay{
  background: rgba(0, 0, 0, 0.9)!important;
}

/* Large Devices, Wide Screens */

@media only screen and (max-width: 1199px) {}


/* Medium Devices, Desktops */

@media only screen and (max-width: 991px) {}


/* Small Devices, Tablets */

@media only screen and (max-width: 768px) {}


/*Доп запросы*/

@media only screen and (max-width: 680px) {}


/* Extra Small Devices, Phones */

@media only screen and (max-width: 486px) {
  /* Форма */
  .sl-form--wrapper {
    padding: 15px;
    margin: 0;
    overflow-y: scroll;
    height: 500px;
  }
  .sl-form {
    overflow-y: scroll;
    height: 700px;
  }
}

@media only screen and (max-width: 380px) {}
