@import 'utils/fonts.styl'
@import 'utils/vars.styl'
@import 'utils/mixins.styl'
@import 'libs.styl'

html
	font-size baseFontSize
	height 100%
	line-height 1.3

body
	font-family mainFont
	height 100%

h1,h2,h3,h4,h5,h6
	margin 0

ul,li
	list-style-type none
	margin 0
	padding 0

p
	margin 0

a
	color black
	text-decoration none

audio,
canvas,
iframe,
img,
svg,
video
	vertical-align middle

textarea
	resize none

section
	position relative

input,
select,
button
	outline none

*, *:before, *:after
	box-sizing border-box




//Модули
@import "modules/modules.styl"

// СТРАНИЦЫ
// Главная страница
@import 'pages/index/index.styl'
// Мобильная версия	
@import 'pages/index/media.styl'